import request from '../utils/request'

export function createRoomToken(room,userId,type) {
    return request.post('./index.php', {
        function: 'createRoomToken',
        room,userId,type
    })
}

export function loginByEducator(email,pwd) {
    return request.post('./index.php', {
        function: 'loginByEducator',
        email,pwd
    })
}

export function loginByEmployee(email,pwd) {
    return request.post('./index.php', {
        function: 'loginByEmployee',
        email,pwd
    })
}

export function loginByStudent(email,pwd) {
    return request.post('./index.php', {
        function: 'loginByStudent',
        email,pwd
    })
}

export function getUserNames() {
    return request.post('./index.php', {
        function: 'getUserNames'
    })
}

export function getMyEvents(student_id) {
    return request.post('./index.php', {
        function: 'getMyEvents',
        student_id
    })
}

export function getEventsByEducator(educator_id) {
    return request.post('./index.php', {
        function: 'getEventsByEducator',
        educator_id
    })
}

export function getMyZhiboRooms(student_id) {
    return request.post('./index.php', {
        function: 'getMyZhiboRooms',
        student_id
    })
}

export function getExerciseTemplateByStudent(template_id,user_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplateByStudent',
        template_id,user_id
    })
}

export function uploadAnswers(answers,student_id,template_id) {
    return request.post('./index.php', {
        function: 'uploadAnswers',
        answers,student_id,template_id
    })
}

export function changeVisibleLog(student_id,template_id) {
    return request.post('./index.php', {
        function: 'changeVisibleLog',
        student_id,template_id
    })
}

export function getTestVisibleChangeCount(template_id,student_id) {
    return request.post('./index.php', {
        function: 'getTestVisibleChangeCount',
        template_id,student_id
    })
}

export function submitAnswer(question_id,template_id,choice,student_id) {
    return request.post('./index.php', {
        function: 'submitAnswer',
        question_id,template_id,choice,student_id
    })
}

export function saveAudioRecord(user_id,section_id,template_id) {
    return request.post('./index.php', {
        function: 'saveAudioRecord',
        user_id,section_id,template_id
    })
}

export function getUser(user_id,user_type) {
    return request.post('./index.php', {
        function: 'getUser',
        user_id,user_type
    })
}

export function listRoomUsers(room) {
    return request.post('./index.php', {
        function: 'listRoomUsers',
        room
    })
}

export function getSchoolScoresByEvent(event) {
    return request.post('./index.php', {
        function: 'getSchoolScoresByEvent',
        event
    })
}

export function getAllZhiboEvents() {
    return request.post('./index.php', {
        function: 'getAllZhiboEvents'
    })
}

export function getAllExams() {
    return request.post('./index.php', {
        function: 'getAllExams'
    })
}

export function getApplicationsByEvent(event) {
    return request.post('./index.php', {
        function: 'getApplicationsByEvent',
        event
    })
}

export function getScoresByEvent(event) {
    return request.post('./index.php', {
        function: 'getScoresByEvent',
        event
    })
}

export function getScoresSelf(event,user_id) {
    return request.post('./index.php', {
        function: 'getScoresSelf',
        event,user_id
    })
}

export function checkTestLinkOpen(student_id,name) {
    return request.post('./index.php', {
        function: 'checkTestLinkOpen',
        student_id,name
    })
}
export function checkZhiboOpen(student_id,roomId) {
    return request.post('./index.php', {
        function: 'checkZhiboOpen',
        student_id,roomId
    })
}

export function checkPeixun() {
    return request.post('./index.php', {
        function: 'checkPeixun'
    })
}

export function getApplicationsByRoom(roomId) {
    return request.post('./index.php', {
        function: 'getApplicationsByRoom',
        roomId
    })
}

export function uploadTimeStamp(student_id,template_id,stamp) {
    return request.post('./index.php', {
        function: 'uploadTimeStamp',
        student_id,template_id,stamp
    })
}

export function getTimeStamp(student_id,template_id) {
    return request.post('./index.php', {
        function: 'getTimeStamp',
        student_id,template_id
    })
}

export function getGroupAwards(project_code,event) {
    return request.post('./index.php', {
        function: 'getGroupAwards',
        project_code,event
    })
}

export function getAwards(project_code,application_event) {
    return request.post('./index.php', {
        function: 'getAwards',
        project_code,application_event
    })
}


export function getMyAward(student_id) {
    return request.post('./index.php', {
        function: 'getMyAward',
        student_id
    })
}